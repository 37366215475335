import React from 'react';
import './css/Investment.css';
import FetchAndRenderMarkdown from '../components/FetchAndRenderMarkdown';
import textblock from '../components/textblock';
import Packaging from '../assets/Packaging.json';
import Package from '../components/Packaging';
import useScrollToAnchor from '../components/useScrollToAnchor'

function Investment() {

    useScrollToAnchor();
    
    return (
        <div className="Investment">
            <FetchAndRenderMarkdown 
                url='/Pages/Investment/Investment.md' 
                Component={textblock}
                className='Section1 centerText center'
                id='Investment'
            />            
            <div className='BrandPhotography center'>
                <FetchAndRenderMarkdown 
                    url='/Pages/Investment/BrandPhotography.md' 
                    Component={textblock}
                    className='Section2 centerText'
                    id='BrandPhotography'
                />
                <span className='Headshots CCrow1'>
                    <Package 
                        Package={Packaging['Headshots'][0].Package} 
                        Price={Packaging['Headshots'][0].Price} 
                        PriceNote={Packaging['Headshots'][0].PriceNote} 
                        Includes={Packaging['Headshots'][0].Includes} 
                    />
                    <Package 
                        Package={Packaging['Headshots'][1].Package} 
                        Price={Packaging['Headshots'][1].Price} 
                        PriceNote={Packaging['Headshots'][1].PriceNote} 
                        Includes={Packaging['Headshots'][1].Includes} 
                    />
                </span>
                <Package 
                    Package={Packaging['Brand Photography'][0].Package} 
                    Price={Packaging['Brand Photography'][0].Price} 
                    PriceNote={Packaging['Brand Photography'][0].PriceNote} 
                    Includes={Packaging['Brand Photography'][0].Includes} 
                />
                <Package 
                    Package={Packaging['Brand Photography'][1].Package} 
                    Price={Packaging['Brand Photography'][1].Price} 
                    PriceNote={Packaging['Brand Photography'][1].PriceNote} 
                    Includes={Packaging['Brand Photography'][1].Includes} 
                />
                <Package 
                    Package={Packaging['Brand Photography'][2].Package} 
                    Price={Packaging['Brand Photography'][2].Price} 
                    PriceNote={Packaging['Brand Photography'][2].PriceNote} 
                    Includes={Packaging['Brand Photography'][2].Includes} 
                />
                <Package 
                    Package={Packaging['Brand Photography'][3].Package} 
                    Price={Packaging['Brand Photography'][3].Price} 
                    PriceNote={Packaging['Brand Photography'][3].PriceNote} 
                    Includes={Packaging['Brand Photography'][3].Includes} 
                />
            </div>
            <div className='ContentCreation center'>
                <FetchAndRenderMarkdown 
                    url='/Pages/Investment/ContentCreation.md' 
                    Component={textblock}
                    className='Section2 centerText'
                    id='ContentCreation'
                />
                <span className='CCrow1'>
                    <Package 
                        Package={Packaging['Content Creation'][0].Package} 
                        Price={Packaging['Content Creation'][0].Price} 
                        PriceNote={Packaging['Content Creation'][0].PriceNote} 
                        Includes={Packaging['Content Creation'][0].Includes} 
                    />
                    <Package 
                        Package={Packaging['Content Creation'][1].Package} 
                        Price={Packaging['Content Creation'][1].Price} 
                        PriceNote={Packaging['Content Creation'][1].PriceNote} 
                        Includes={Packaging['Content Creation'][1].Includes} 
                    />
                </span>
                <Package 
                    Package={Packaging['Content Creation'][2].Package} 
                    Price={Packaging['Content Creation'][2].Price} 
                    PriceNote={Packaging['Content Creation'][2].PriceNote} 
                    Includes={Packaging['Content Creation'][2].Includes} 
                />
            </div>
            <div className='OfferingsLaunches center'>
                <FetchAndRenderMarkdown 
                    url='/Pages/Investment/OfferingsLaunches.md' 
                    Component={textblock}
                    className='Section2 centerText'
                    id='OfferingsLaunches'
                />
                <span className='CCrow1'>
                    <Package 
                        Package={Packaging['Offerings & Launches'][0].Package} 
                        Price={Packaging['Offerings & Launches'][0].Price} 
                        PriceNote={Packaging['Offerings & Launches'][0].PriceNote} 
                        Includes={Packaging['Offerings & Launches'][0].Includes} 
                    />
                    <Package 
                        Package={Packaging['Offerings & Launches'][1].Package} 
                        Price={Packaging['Offerings & Launches'][1].Price} 
                        PriceNote={Packaging['Offerings & Launches'][1].PriceNote} 
                        Includes={Packaging['Offerings & Launches'][1].Includes} 
                    />
                </span>
                <Package 
                    Package={Packaging['Offerings & Launches'][2].Package} 
                    Price={Packaging['Offerings & Launches'][2].Price} 
                    PriceNote={Packaging['Offerings & Launches'][2].PriceNote} 
                    Includes={Packaging['Offerings & Launches'][2].Includes} 
                />
            </div>
            <div className='EventPhotography center'>
                <FetchAndRenderMarkdown 
                    url='/Pages/Investment/EventPhotography.md' 
                    Component={textblock}
                    className='Section2 centerText'
                    id='EventPhotography'
                />
                <span className='CCrow1'>
                    <Package 
                        Package={Packaging['Event Photography'][0].Package} 
                        Price={Packaging['Event Photography'][0].Price} 
                        PriceNote={Packaging['Event Photography'][0].PriceNote} 
                        Includes={Packaging['Event Photography'][0].Includes} 
                    />
                    <Package 
                        Package={Packaging['Event Photography'][1].Package} 
                        Price={Packaging['Event Photography'][1].Price} 
                        PriceNote={Packaging['Event Photography'][1].PriceNote} 
                        Includes={Packaging['Event Photography'][1].Includes} 
                    />
                </span>
                <Package 
                    Package={Packaging['Event Photography'][2].Package} 
                    Price={Packaging['Event Photography'][2].Price} 
                    PriceNote={Packaging['Event Photography'][2].PriceNote} 
                    Includes={Packaging['Event Photography'][2].Includes} 
                />
            </div>
            <FetchAndRenderMarkdown 
                url='/Pages/Investment/Customized.md' 
                Component={textblock}
                className='Customized center centerText'
                id='CustomizedPhotography'
            />
        </div>
    );
}

export default Investment;