import React, { createContext } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import pages from './pages/pagesConfig';
import Navigation from './components/Navigation';
import RoutePathProvider from './components/RoutePathProvider';
import Footer from './components/Footer';

const RoutePathContext = createContext('/');

function App() {  
  return (
    <Router>
      <Helmet>
          <html lang="en" />
          <title>Michelle Saul | Portland, Oregon Photographer & Brand Consultant</title>
          <meta name="description" content="Unlock the full potential of your Oregon-based business with Flourish Brand Photography's expert branding photography services. Flourish Brand Photography helps local businesses like yours shine with professional, high-quality images. Our custom branding photos boost increased brand visibility, foster improved customer engagement, and enhance marketing efforts. You are invited to come and explore how we can help you thrive! Book a complimentary consultation to learn more about how we can transform your online presence!" />
          <meta name="keywords" content="
            Michelle Saul, Photographer,
            Photography,
            Branding, Personal Brand Photography,
            Headshots,
            Family Photos,
            Family Portraits,
            Creative Photography,
            Fashion, Fashion Photography,
            Product, Product Photography,
            Event, Event Photography,
            Marketing,
            Customized, Customized Photography,
            HDR photography,
            Business Photography
            Corporate Event Photography,
            Book a photoshoot,
            get quotes for photography,
            compare prices for photographers,
            Photographer in hillsboro oregon, 
            Family Portrait in Oregon, 
            Portland Oregon, Portland,
            Websites Created by Zane Saul
          " />
          <meta name="author" content="Zane M. Saul" />
          <meta name="developer" content="Zane M. Saul" />
          <meta name="partner" content="https://zanesaul.com/" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://flourishbrandphotography.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Michelle Saul | Portland, Oregon Photographer & Brand Consultant" />
          <meta property="og:description" content="Unlock the full potential of your Oregon-based business with Flourish Brand Photography's expert branding photography services. Flourish Brand Photography helps local businesses like yours shine with professional, high-quality images. Our custom branding photos boost increased brand visibility, foster improved customer engagement, and enhance marketing efforts. You are invited to come and explore how we can help you thrive! Book a complimentary consultation to learn more about how we can transform your online presence!" />
          <meta property="og:url" content="https://flourishbrandphotography.com/" />
          <meta property="og:site_name" content="Flourish Brand Photography" />
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "Flourish Brand Photography",
                "url": "https://flourishbrandphotography.com",
                "jobTitle": "",
                "worksFor": {
                  "@type": "Organization",
                  "name": "Independent"
                },
                "sameAs": [
                  "https://www.linkedin.com/in/michellensaul/",
                  "https://www.instagram.com/flourishbrandphotography/"
                ]
              }
            `}
          </script>
      </Helmet>
      <RoutePathProvider RoutePathContext={RoutePathContext}>
        <div className="App">
          <div className='header'>
            <Navigation RoutePathContext={RoutePathContext} />
          </div>
          <div className='body'>
          <Routes>
            {pages.map((page, index) => (
              <Route key={index} path={page.path} element={
                <div className='body-section' id='default'>
                  <page.component />
                </div>
              } />
            ))}
          </Routes>
            <Footer/>
          </div>
        </div>
      </RoutePathProvider>
    </Router>
  );
}

export default App;