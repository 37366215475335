import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DEFAULT_ANCHOR_ID = "default";

const useScrollToAnchor = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace("#", "");
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 400); // delay of 400ms
        } else {
            setTimeout(() => {
                const element = document.getElementById(DEFAULT_ANCHOR_ID);
                if (element) {
                    element.scrollIntoView({ behavior: 'instant' });
                }
            }, 1); // default delay of 100ms
        }
    }, [location]);
};

export default useScrollToAnchor;